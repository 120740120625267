/**
 * Space
 */

.margin {
	&-top {
		&-0 {
			margin-top: 0;
		}

		&-27 {
			margin-top: 24px;
		}
	}

	&-bottom {
		&-24 {
			margin-bottom: 24px;
		}

		&-30 {
			margin-bottom: 24px;
		}

		&-36 {
			margin-bottom: 36px;
		}
	}
}