/**
 * Global style
 */
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  word-break: keep-all; // prevent word break in Chinese, Japanese, Korean
}

body {
  font-family: "Pretendard Variable",
    Pretendard,
    -apple-system,
    BlinkMacSystemFont,
    system-ui,
    Roboto,
    "Helvetica Neue",
    "Segoe UI",
    "Apple SD Gothic Neo",
    "Noto Sans KR",
    "Malgun Gothic",
    "Apple Color Emoji",
    "Segoe UI Emoji",
    "Segoe UI Symbol",
    sans-serif;
  font-size: 1rem;
  background-color: #fff;
  color: #2d334a;
}

label {
  font-size: 14px;
  line-height: 1.5;
  font-weight: 400;
  color: #152935;
}

p {
  font-size: 14px;
  line-height: 1.5;
  font-weight: 400;
}

h2 {
  font-size: 18px;
  font-weight: 700;
  line-height: 1.2;
}