@import './mixin';

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.nda-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  background-color: #f8f8f8;
  box-sizing: border-box;
  padding: 1.5rem 0;
  row-gap: 1rem;

  @include sm {
    row-gap: 2rem;
    padding: 3rem 0;
  }

  &__company-logo {
    height: 35px;
    max-width: 190px;
    animation: fade-in 2s ease-out normal;

    @include sm {
      height: 40px;
      max-width: 220px;
    }

    @include md {
      height: 50px;
      max-width: 300px;
    }

    @include lg {
      height: 80px;
      max-width: 400px;
    }
  }

  &__box {
    width: 87vw;
    max-width: 1200px;
    border-radius: 12px;
    box-shadow: 0px 8px 15px 2px rgba(0, 0, 0, 0.25);
    background-color: #fff;
    padding: 1rem;

    @include sm {
      padding: 2rem;
      width: 75vw;
    }
  }

  &__title {
    font-weight: 600;
    margin-bottom: 1.5rem;
    font-size: 1rem;

    @include md {
      font-size: 1.25rem;
    }
  }

  &__content {
    padding: 20px;
    border: 1px solid #BACAD5;
    border-radius: 8px;
    overflow-x: scroll;
    animation: fade-in 2s ease-out normal;


    p {
      font-size: 0.875rem;

      @include md {
        font-size: 1rem;
      }
    }

    @media screen and (max-width: 320px) {
      span[style*="font-size: 20.0pt;"] {
        font-size: 1rem !important;
      }

    }

  }

  &__filler {
    flex-grow: 1;
  }

  &__footer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__signature {
    color: rgba(148, 149, 155, 0.6);
    font-size: 1rem;

    @include sm {
      font-size: 1.125rem;
    }
  }

  &__links {
    display: flex;
    column-gap: 1.5rem;
    font-family: 'Lato', sans-serif;

    a {
      text-decoration: none;
      color: #94959B;
      font-size: 0.875rem;

      @include sm {
        font-size: 1rem;
      }
    }
  }
}