/**
 * App
 */
.app {
  padding: 30px;

  &__main {
    position: relative;

    .spinner-wrap {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 768px;
      background-color: rgba(0, 0, 0, 0.5);
    }
  }

  &__logo {
    width: 200px;
    height: auto;
  }
}
