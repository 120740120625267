/**
 * Text
 */
.text {
  &__required-mark {
    color: #e71d32;
  }

  &__description {
    color: #5a6872;
  }

  &__icon {
    img {
      width: 16px;
      height: auto;
      display: inline-block;
      vertical-align: middle;
      margin-right: 16px;
    }

    span {
      font-weight: 400;
    }

    label {
      font-weight: 700;
    }
  }

  &--center {
    text-align: center;
  }
}
