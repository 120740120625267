/**
 * Form
 */
.form {
	max-width: 768px;

	&--boxed {
		border: 1px solid #DFE3E6;
		padding: 17px 25px;
	}

	&__description {
		width: 100%;
		padding-bottom: 8px;
		position: relative;

		&:after {
			position: absolute;
			content: '';
			height: 1px;
			width: 85%;
			left: 0;
			bottom: 0;
			background-color: rgba(35, 35, 35, 0.2);
		}
	}

	&__field {
		margin-bottom: 13px;
	}

	&__button-group {
		display: flex;
   		margin: 0 -10px;

		.button {
			margin: 0 10px;
		}
	}
}