.qr-code {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: auto;

  &__box {
    flex-shrink: 0;
    border: 3px solid #000;
    border-radius: 10px;
    background-color: #fff;
  }

  .text__icon {
    margin-top: 10px;
  }
  svg {
    flex: 1;
  }
}
