/**
 * Button
 */
.button {
  cursor: pointer;
  padding: 11px 16px;
  appearance: none;
  border: 2px solid;
  font-weight: 500;
  width: 100%;
  border-radius: 0;
  background-color: transparent;

  &--primary {
    border-color: #f25f4c;
    background-color: #f25f4c;

    &:disabled {
      border-color: #dfe3e6;
      background-color: #dfe3e6;
      cursor: auto;
    }
  }
}
