/**
 * Field
 */
.field {
  &__heading {
    margin: 0 0 20px;
    font-size: 14px;
    font-weight: 700;
  }

  &__label {
    display: block;
    margin-bottom: 5px;
    letter-spacing: 0.32px;
  }

  &__control {
    display: block;
    padding: 11px 16px;
    font-size: 14px;
    line-height: 1.3;
    width: 100%;
    appearance: none;
    background-color: #ebf2fd;
    border: 1px solid #bacad5;
    border-radius: 0;

    &:read-only {
      background-color: #eff1f2;
    }

    &--select {
      background-image: url("data:image/svg+xml,%3Csvg width='10' height='5' viewBox='0 0 10 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0 0L5 5L10 0H0Z' fill='%235A6872'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position: right 16px center;
      background-size: 10px 5px;

      &:read-only {
        background-color: #ebf2fd;
      }

      &:disabled {
        background-color: #eff1f2;
      }
    }

    &[type="text"],
    &[type="tel"],
    &[type="email"] {
      .field__has-error & {
        border-color: #e71d32;
        background-color: #ffcbd0;
        background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 17C13.4183 17 17 13.4183 17 9C17 4.58172 13.4183 1 9 1C4.58172 1 1 4.58172 1 9C1 13.4183 4.58172 17 9 17Z' stroke='%23E71D32' stroke-width='1.33333'/%3E%3Cpath d='M9 12.6V13M9 5V9.8V5Z' stroke='%23E71D32' stroke-width='1.33333' stroke-linecap='round'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-size: 16px;
        background-position: right 20px center;
        padding-right: 40px;
      }
    }
  }

  &__radio {
    display: flex;
    column-gap: 0.75rem;
    margin-bottom: 0.5rem;

    &-button {
      cursor: pointer;
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 2px solid #ccc;
      outline: none;
      background-color: #fff;

      &:checked {
        background-color: #f25f4c;
      }
    }

    &-label {
      cursor: pointer;
    }
  }

  &__check-label {
    display: block;
    position: relative;
    cursor: pointer;
    padding: 0;
    color: #2d334a;
    padding-left: 30px;

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      content: "";
      margin-right: 10px;
      display: inline-block;
      vertical-align: text-top;
      width: 16px;
      height: 16px;
      background: #fff;
      border: 1px solid #000;
    }
  }

  &__check-control {
    position: absolute;
    opacity: 0;

    &:checked+.field__check-label:after {
      content: "";
      position: absolute;
      top: 0;
      left: 5px;
      width: 6px;
      height: 12px;
      border: 2px solid #000;
      transform: rotate(43deg);
      border-top: 0;
      border-left: 0;
    }
  }

  &__text-error {
    font-size: 12px;
    color: #e71d32;
    margin: 4px 0 0;
  }

  &__terms {
    border: 1px solid #bacad5;
    padding: 10px 15px;
    max-height: 445px;
    overflow-y: auto;

    h1 {
      font-size: 14px;
    }

    h2 {
      font-size: 13px;
    }

    h3 {
      font-size: 12px;
    }

    h1,
    h2,
    h3 {
      position: relative;
      padding-bottom: 1em;
      margin-bottom: 1em;
      font-weight: 700;

      &:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        height: 1px;
        width: 90%;
        background-color: #000000;
        opacity: 0.1;
      }
    }
  }
}